import React, {useEffect, useRef, useState} from 'react';
import './EasyCMS.css';
import Navbar from './components/Navbar/Navbar';
import Home from './pages/Home/Home';
import Location from './pages/Location/Location';
import Zimmer from './pages/Zimmer/Zimmer';
import Partner from './pages/Partner/Partner';
import Contact from './pages/Contact/Contact';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import axios from "axios";
import useWindowSize from "./hooks/useWindowSize";
import Wrapper from './pages/Wrapper';
import Cuisine from "./pages/Cuisine/Cuisine";
import {backendURL} from "./lib/environment";
import {Snackbar} from "@material-ui/core";

interface Data {
    home: HomeType,
    cuisine: CuisineType,
    location: LocationType,
    rooms: RoomsType,
    partner: PartnerType,
    contact: ContactType
}

export type HomeType = typeof homeData
export type CuisineType = typeof cuisineData
export type LocationType = typeof locationData
export type RoomsType = typeof roomsData
export type PartnerType = typeof partnerData
export type ContactType = typeof contactData
export type ComponentType = HomeType | CuisineType | LocationType | RoomsType | PartnerType | ContactType
export type VesselType = { slug: string, name: string, data: ComponentType }

interface Props {
    setAlert: (alert: { text: string, cb: () => void }) => void
    thirdPartyCookies?: boolean
    width?: number
    reloadPreview: (slug?: string) => void
    setShowPreview: (bool: boolean) => void
    showPreview: boolean
    dragging: boolean

}

const EasyCMS: React.FC<Props> = ({
                                      setAlert,
                                      thirdPartyCookies,
                                      width,
                                      reloadPreview,
                                      setShowPreview,
                                      showPreview,
                                      dragging
                                  }) => {
    const [windowWidth] = useWindowSize()

    if (width === undefined) {
        width = windowWidth
    }

    const [data, setData] = useState<Data>()

    useEffect(() => {
        axios.get<VesselType[]>(backendURL + "/api/vessel")
            .then(result => {
                console.log(result)
                setData({
                    contact: result.data.find(x => x.slug === "/contact")?.data as ContactType,
                    cuisine: result.data.find(x => x.slug === "/cuisine")?.data as CuisineType,
                    location: result.data.find(x => x.slug === "/location")?.data as LocationType,
                    partner: result.data.find(x => x.slug === "/partner")?.data as PartnerType,
                    rooms: result.data.find(x => x.slug === "/rooms")?.data as RoomsType,
                    home: result.data.find(x => x.slug === "/home")?.data as HomeType
                })
            })

        //    mockup backend
        //
        // setData({
        //     home: homeData,
        //     cuisine: cuisineData,
        //     location: locationData,
        //     rooms: roomsData,
        //     partner: partnerData,
        //     contact: contactData
        // })

    }, [])

    const containerRef = useRef(null)

    // speichern
    const save = () => {
        axios.put(backendURL + "/api/vessel", data)
            .then(r => {
                console.log("Gespeichert", r.data)
                setSuccess(true)
            })
            .catch(e => {
                console.log("Speichern nicht möglich", e)
                setFailure(true)
            })
    }


    // const activatePreview = () => {
    //
    //     // if third party cookies are enabled:
    //     if (thirdPartyCookies) {
    //         setShowPreview(!showPreview)
    //     } else {
    //         setAlert({
    //             text: "Third Party Cookies sind deaktiviert. Das Preview wird in einem neuen Tab geöffnet.",
    //             cb: () => {
    //                 const link = document.createElement("a")
    //                 link.target = "_blank"
    //                 link.href = clientURL + "/api/preview?secret=MY_SECRET_TOKEN&slug=/home"
    //                 link.click()
    //                 link.remove()
    //             }
    //         })
    //     }
    // }

    const [success, setSuccess] = useState<boolean>(false)
    const [failure, setFailure] = useState<boolean>(false)

    if (data === undefined) return <div>Laden...</div>


    const handleClose = () => {
        setSuccess(false)
    }
    const handleCloseFailure = () => {
        setFailure(false)
    }
    return (
        <div ref={containerRef} className={width < 600 ? "mobile-container" : "container"}
             style={dragging ? {display: "none"} : {}}>
            <Snackbar open={success}
                // anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                      autoHideDuration={5000}
                      onClose={handleClose}
                      message={"Erfolgreich gespeichert"}
            />
            <Snackbar open={failure}
                // anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                      autoHideDuration={5000}
                      onClose={handleCloseFailure}
                      message={"Speichern fehlgeschlagen"}
            />
            <Router>
                <Navbar width={width}/>
                <div className="inner-container">
                    <Switch>
                        <Route path="/" exact={true}>
                            <Wrapper component={Home}
                                     slug={"/home"}
                                     setPreview={reloadPreview}
                                     data={data.home}
                                     setData={(home) => setData({...data, home})}/>
                        </Route>
                        <Route path="/location">
                            <Wrapper component={Location}
                                     key={"/location"}
                                     slug={"/location"}
                                     setPreview={reloadPreview}
                                     data={data.location}
                                     setData={(location) => setData({...data, location})}/>
                        </Route>
                        <Route path="/cuisine">
                            <Wrapper component={Cuisine}
                                     key={"/cuisine"}
                                     slug={"/cuisine"}
                                     setPreview={reloadPreview}
                                     data={data.cuisine}
                                     setData={(cuisine) => setData({...data, cuisine})}/>
                        </Route>
                        <Route path="/rooms">
                            <Wrapper component={Zimmer}
                                     key={"/rooms"}
                                     slug={"/rooms"}
                                     setPreview={reloadPreview}
                                     data={data.rooms}

                                     setData={(rooms) => setData({...data, rooms})}/>
                        </Route>
                        <Route path="/partner">
                            <Wrapper component={Partner}
                                     key={"/partner"}
                                     slug={"/partner"}
                                     setPreview={reloadPreview}
                                     data={data.partner}
                                     setData={(partner) => setData({...data, partner})}/>
                        </Route>
                        <Route path="/contact">
                            <Wrapper component={Contact}
                                     key={"/contact"}
                                     slug={"/contact"}
                                     setPreview={reloadPreview}
                                     data={data.contact}
                                     setData={(contact) => setData({...data, contact})}/>
                        </Route>
                    </Switch>
                </div>

            </Router>

            <div className={"controls"}>
                {/*toggles preview*/}
                {/*<button onClick={activatePreview}>Vorschau*/}
                {/*</button>*/}
                {/*Publishes data*/}
                <button onClick={() => save()}>Speichern</button>
            </div>
        </div>
    );
}

export default EasyCMS;

const contactData =
    {
        heading: "Kontakt",
        name:
            "Gasthof Stöffelbauer",
        address:
            "Linzer Straße 17, A-3350 Haag",
        phoneNumber: "+43 7434 42310",
        email: "office@stefflwirt.at",
        text: "Zimmerreservierungen telefonisch oder per Mail!\n" +
            "\n" +
            "Geänderte Öffnungszeiten:\n" +
            "Mittwoch 17:00 - 23:00 Uhr\n" +
            "Do - Sa 10:00 - 14:00 Uhr, 17:00 - 23:00\n" +
            "Sonntag und Feiertag 10:00 - 14:00 Uhr\n" +
            "\n" +
            "Küche geöffnet: 11:30 - 14:00 Uhr,\n" +
            "18:00 - 21:00 Uhr",
    }

const partnerData =
    {
        heading: "Unsere Partner",
        categories: [{
            name: "Fleisch",
            partners: [
                {
                    name: "Ellegast GesmbH",
                    address: "Hauptplatz 5, 3350 Haag"
                }
            ]
        }, {
            name: "Most",
            partners: [{
                name: "Familie Stöckler",
                address: "Edelhof 40, 3350 Haag"
            }]
        }, {
            name: "Schnaps",
            partners: [{
                name: "Georg Hiebl",
                address: "Reichhub 36, 3350 Haag"
            }]
        }, {
            name: "Brot und Gebäck",
            partners: [{
                name: "Bäckerei Kammerhofer",
                address: "Wienerstraße 15, 3350 Haag"
            }]
        }, {
            name: "Mehl",
            partners: [{
                name: "Reitbauer Betriebs- & Handels GmbH",
                address: "Vestenthal 19, 4431 Haidershofen"
            }]
        }
        ]
    }

const roomsData =
    {
        headingRooms: "Unsere Zimmer",
        contentRooms: "Wir bieten Übernachtungsmöglichkeiten an unserem Gasthof an. Bei unseren Zimmern handelt es sich um sehr einfache Einzel-, Doppel- oder Dreibettzimmer. Außerdem haben wir auch eine Ferienwohnung für 4 Personen anzubieten. Im Preis inkludiert ist ein einfaches Frühstück in unserem Gasthof.",
        headingPrice: "Preise",
        prices: "Einzelzimmer 41 ,- p.P\n" +
            "\n" +
            "Doppelzimmer 36 ,- p.P\n" +
            "\n" +
            "Dreibettzimmer 36 ,- p.P\n" +
            "\n" +
            "Ferienwohnung 41 ,- p.P"
        ,
        infoText: "Preise inkl. Frühstück",
        images: [{
            src: "/img/rooms/Zimmer1.jpg",
            width: 4512,
            height: 3000
        }, {
            src: "/img/rooms/Zimmer2.jpg",
            width: 4512,
            height: 3000
        }, {
            src: "/img/rooms/Zimmer5.jpg",
            width: 4512,
            height: 3000
        }, {
            src: "/img/rooms/Zimmer6.jpg",
            width: 4512,
            height: 3000
        }, {
            src: "/img/rooms/Zimmer7.jpg",
            width: 4512,
            height: 3000
        }, {
            src: "/img/rooms/Zimmer8.jpg",
            width: 4512,
            height: 3000
        },]

    }

const locationData =
    {
        locations: [
            {
                heading: "Das Extrazimmer",
                content: "Das Extrazimmer ist nicht nur ideal für kleine Feiern wie Taufe, Geburtstage usw. sondern auch ein idealer Platz für schöne Abende zu zweit oder für ein Mittagessen mit den Kollegen. Insgesamt können hier ca. 30 Personen Platz nehmen.",
                images: [
                    {
                        src: "/img/location/DSC_1307.JPG",
                        width: 4512,
                        height: 3000
                    },
                    {
                        src: "/img/location/DSC_0991.JPG",
                        width: 4512,
                        height: 3000
                    },

                ]
            },
            {
                heading: "Der Saal",
                content: "In unserem großem Saal werden sowohl Weihnachtsfeiern, Geburtstage als auch kleine Hochzeiten gefeiert. Weiters bietet er an Sonntagen beim Frühschoppen einen perfekten Platz für unsere Stammtische. Ein kleines Special hier ist unser Billardtisch, an dem sich abends unsere Gäste gerne ein Match bieten.",
                images: [
                    {
                        src: "/img/location/DSC_1308.JPG",
                        width: 4512,
                        height: 3000
                    },
                    {
                        src: "/img/location/DSC_1310.JPG",
                        width: 4512,
                        height: 3000
                    },
                    {
                        src: "/img/location/DSC_1311.JPG",
                        width: 4512,
                        height: 3000
                    },
                    {
                        src: "/img/location/DSC_1312.JPG",
                        width: 4512,
                        height: 3000
                    },
                    {
                        src: "/img/location/DSC_1319.JPG",
                        width: 4512,
                        height: 3000
                    }
                ]
            },
            {
                heading: "Das Gastzimmer",
                content: "Unser Gastzimmer ist eine gute Möglichkeit mit Leuten ins Gespräch zu kommen. Hier haben etliche Stammtische ihren Platz und auch mittags finden sich immer wieder Gäste ihren Platz im gemütlichen Stüberl, welches Platz für etwa 25 Personen bietet.",
                images: [
                    {
                        src: "/img/location/DSC_1365.JPG",
                        width: 4512,
                        height: 3000
                    },
                    {
                        src: "/img/location/DSC_1367.JPG",
                        width: 4512,
                        height: 3000
                    },
                    {
                        src: "/img/location/DSC_1369.JPG",
                        width: 4512,
                        height: 3000
                    }]
            },
            {
                heading: "Der Gastgarten",
                content: "Der Gastgarten vor dem Haus ist perfekt an warmen Tagen zum Mittagessen oder den Abend schön ausklingen zu lassen.",
                images: [
                    {
                        src: "/img/location/DSC_0994.JPG",
                        width: 4512,
                        height: 3000
                    },
                    {
                        src: "/img/location/DSC_1003.JPG",
                        width: 4512,
                        height: 3000
                    },
                    {
                        src: "/img/location/DSC_0999.JPG",
                        width: 4512,
                        height: 3000
                    },
                    {
                        src: "/img/location/DSC_1001.JPG",
                        width: 4512,
                        height: 3000
                    }
                ]
            }
        ]
    }
const homeData =
    {
        showInfo: true,
        currentMenu: {
            name: "Kitchen to go",
            dishes: [
                {
                    name: "Spargelcordon mit Petersilienkartoffeln und Salat",
                    price: 14.5,
                    allergens: []
                },
                {
                    name: "Kalbsrahmbeuscherl mit Semmelknödel",
                    price: 13.5,
                    allergens: []
                },
                {
                    name: "Gulaschsaft (pro Portion)",
                    price: 3.5,
                    allergens: []
                },
                {
                    name: "Schnitzerl mit Petersilkartofferl oder Pommes",
                    price: 12.9,
                    allergens: []
                },
                {
                    name: "Cordon Bleu mit Kartoffeln und Salat",
                    price: 13.9,
                    allergens: []
                },
                {
                    name: "Bauern Cordon Bleu mit Kartoffeln und Salat",
                    price: 14.9,
                    allergens: []
                },
                {
                    name: "Gulasch mit Knödel",
                    price: 10.5,
                    allergens: []
                },
                {
                    name: "Stöffelburger mit Steakpommes",
                    price: 10.5,
                    allergens: []
                },
                {
                    name: "Tellerfleisch",
                    price: 7.8,
                    allergens: []
                },
                {
                    name: "Rindssuppe (1 Liter)",
                    price: 5,
                    allergens: []
                },
                {
                    name: "Stockfischsuppe",
                    price: 9.5,
                    allergens: []
                },
                {
                    name: "Spargelcremesuppe",
                    price: 4,
                    allergens: []
                },
                {
                    name: "Kaspressknödelsuppe",
                    price: 4,
                    allergens: []
                },
            ]
        }
// , infoText: "von Mittwoch bis Sonntag" +
//     "\n" +
//     "Bestellung von 10 - 11 Uhr mit Abholung bis 13 Uhr" +
//     "\n" +
//     "Telefonnummer: +43 7434 42310",
    ,
    infoText: "Hallo",
    infoTitle: "Wir suchen Verstärkung",
    titleImage: {
        src: "http://localhost:3001/img/titelbild.jpg",
        alt: "Gasthof Stöffelbauer",
        height: 3000,
        width: 4512,
        blurDataURL: undefined

    }
    ,
    greetingHeading: "Griaß eich",
    greeting: "Unser Gasthaus ist ein echter Familienbetrieb. 1949 gründete Michael Stöffelbauer Sen. den Betrieb, der 1984 von seinem Sohn Michael Stöffelbauer Jun. übernommen wurde. Mit der von Martina Stöffelbauer exzellent geführten gutbürgerlichen Küche entwickelte sich der Gasthof zu einem beliebten Fixpunkt in der regionalen kulinarischen Landschaft.",
    familyPicture: {
        src: "http://localhost:3001/img/Familie.jpg",
        alt: "Familie Stöffelbauer",
        height: 2460,
        width: 3700,
    }
    ,
    openingHoursHeading: "Unsere Öffnungszeiten",
    openingHoursText: "Mittwoch 17:00 - 23:00 Uhr\n" +
        "Do - Sa 10:00 - 14:00 Uhr\n" +
        "17:00 - 23:00 Uhr\n" +
        "Sonn- und Feiertag 10:00 - 14:00 Uhr\n" +
        "\n" +
        "Küche geöffnet: 11:30 - 14:00 Uhr\n" +
        "18:00 - 21:00 Uhr",
    gallery: [
        {
            src: "/img/dishes/Burger.jpg",
            alt:
                "Stefflburger",
            height:
                2826,
            width:
                4250,
        },
        {
            src: "/img/dishes/Gemusepalatschinken.JPG",
            alt:
                "Gemüsepalatschinken",
            height:
                3000,
            width:
                4512,
        },
        {
            src: "/img/dishes/Ripperl.JPG",
            alt:
                "Ripperl",
            height:
                2849,
            width:
                4285,
        },
        {
            src: "/img/dishes/Schnitzel.JPG",
            alt:
                "Schnitzel",
            height:
                2792,
            width:
                4312,
        },
        {
            src: "/img/dishes/Spargelcordon.JPG",
            alt:
                "Spargelcordon",
            height:
                3000,
            width:
                4512,
        }
    ],
    openingHours: {
        monday: [],
        tuesday: [],
        wednesday: [{
            start: "17:00",
            end: "23:00"
        }],
        thursday: [{
            start: "10:00",
            end: "14:00"
        }, {
            start: "17:00",
            end: "23:00"
        }],
        friday: [{
            start: "10:00",
            end: "14:00"
        }, {
            start: "17:00",
            end: "23:00"
        }],
        saturday: [{
            start: "10:00",
            end: "14:00"
        }, {
            start: "17:00",
            end: "23:00"
        }],
        sunday: [{
            start: "10:00",
            end: "14:00"
        }],
        kitchen: [{
            start: "11:30",
            end: "14:00"
        }, {
            start: "18:00",
            end: "21:00"
        }]
    }
}

const cuisineData =
    {
        heading: "Unsere Speisekarte",
        menu: [
            {
                name: "Suppen",
                dishes: [
                    {
                        name: "Frittatensuppe",
                        allergens: ["A", "C", "G", "L"],
                        price: 3.50,
                    },
                    {
                        name: "Kaspressknödelsuppe",
                        allergens: ["A", "C", "G", "L"],
                        price: 4
                    },
                    {
                        name: "Knoblauchcremesuppe",
                        allergens: ["G", "L"],
                        price: 4
                    },
                ]
            },
            {
                name: "Kleine Gerichte", dishes: [
                    {
                        name: "Gebackene Hühnerbruststreifen auf gemischtem Saltteller",
                        allergens: ["A", "C"],
                        price: 9.5
                    },
                    {
                        name: "Garnelen im Kartoffelnest auf gemischtem Salatteller",
                        allergens: ["B", "D"],
                        price: 10.5
                    },
                    {
                        name: "Kalter Tafelspitz in Balsamico",
                        allergens: ["L"],
                        price: 7.8
                    },
                    {
                        name: "Rindsgulasch mit Semmelknödel",
                        allergens: ["A", "C", "G"],
                        price: 10.5
                    },
                    {
                        name: "Tellerfleisch - Nudelsuppe mit Rindfleisch",
                        allergens: ["A", "C", "G", "L"],
                        price: 7.8
                    },
                    {
                        name: "Frisches Gebäck aus dem Backofen - 8 Min",
                        allergens: ["A", "G", "N"],
                        price: 1.6,
                        vegetarian: true
                    },
                ]
            },
            {
                name: "Mostviertler Gerichte", dishes: [
                    {
                        name: "Hausgemachte Fleisch-, Grammel- und Speckknödel mit Kartofferl und Sauerkraut",
                        allergens: ["A", "C", "L"],
                        price: 11.5
                    },
                    {
                        name: "Gekochter Tafelspitz mit Gemüse und Röstinchen",
                        allergens: ["L"],
                        price: 14.5
                    },
                    {
                        name: "Geröstete Knödel mit Ei und Salat",
                        allergens: ["A", "C", "G"],
                        price: 10.5,
                        vegetarian: true
                    },
                    {
                        name: "Bauern Cordon Bleu mit Käse, Geselchtem, Zwiebel, Kren, Bratkartofferl und Salat",
                        allergens: ["A", "C", "G"],
                        price: 14.5
                    },
                ]
            },
            {
                name: "Aus dem Gemüsegarten", dishes: [
                    {
                        name: "Überbackene Gemüsepalatschinke mit Salat",
                        allergens: ["A", "C", "G", "L"],
                        price: 10.5,
                        vegetarian: true
                    },
                    {
                        name: "Spinatknödel in Gorgonzolasauce mit Salat",
                        allergens: ["A", "C", "G", "L"],
                        price: 10.5,
                        vegetarian: true
                    },
                    {
                        name: "Gemischter Salatteller",
                        allergens: [],
                        price: 4.5,
                        vegetarian: true
                    },
                ]
            }, {
                name: "Hauptgerichte", dishes: [
                    {
                        name: "Gebackenes Schnitzerl vom Schwein oder Pute mit Kartofferl und Salat",
                        allergens: ["A", "C"],
                        price: 12.9
                    },
                    {
                        name: "Cordon-Bleu vom Schwein oder Pute mit Reis, Kartofferl und Salat",
                        allergens: ["A", "C", "G"],
                        price: 13.9
                    },
                    {
                        name: "Gegrilltes Putenfilet in Schnittlauchsauce mit Reis und Gemüse",
                        allergens: ["G", "L"],
                        price: 12.5
                    },
                    {
                        name: "Gebratenes Zanderfilet mit Petersilkartofferl und Salat",
                        allergens: ["A"],
                        price: 17.5
                    },
                    {
                        name: "\"Stöffel Burger\" mit Speck, Zwiebel, Tomate, Käse und Steak Pommes",
                        allergens: ["A", "C", "G", "N"],
                        price: 10.5
                    },
                    {
                        name: "Eierschwammerl à la creme mit Semmelknödel",
                        allergens: ["A"],
                        price: 13.5,
                        vegetarian: true
                    },
                    {
                        name: "Kalbsbeuscherl mit Knödel",
                        allergens: [],
                        price: 13.5
                    },
                ]
            }, {
                name: "Für unsere kleinen Gäste", dishes: [
                    {
                        name: "Kinderschnitzerl mit Pommes",
                        allergens: ["A", "C"], price: 5.9
                    },
                    {
                        name: "Grillwürstel mit Pommes",
                        allergens: [], price: 5.9
                    },
                    {
                        name: "Gegrillte Kinder Pute mit Reis",
                        allergens: [], price: 5.9
                    },
                    {
                        name: "Hühnernuggets mit Pommes",
                        allergens: ["A", "C"], price: 5.9
                    },
                ]
            },
            {
                name: "Zum Naschen", dishes: [
                    {
                        name: "Lauwarmer Schokokuchen mit Beeren",
                        allergens: ["A", "C", "G", "H"],
                        price: 6.9
                    },
                    {
                        name: "Palatschinken (2 Stück) mit Marillenmarmelade",
                        allergens: ["A", "C", "G"],
                        price: 4.5
                    },
                    {
                        name: "Gebackenes Eis mit Erdbeermark",
                        allergens: ["A", "C", "G", "H"],
                        price: 6.9
                    },
                    {
                        name: "Eispalatschinke mit Vanilleeis und Schokosauce",
                        allergens: ["A", "C", "G", "H"],
                        price: 5.5
                    },
                    {
                        name: "Wachauertorte",
                        allergens: ["A", "C", "H"],
                        price: 3.5,

                    },
                ]
            },

        ]
    }
