let stefflwirtURL;
let myBackendURL;

if (process.env.NODE_ENV === "development") {
    stefflwirtURL = "https://stefflwirt.at";
    // stefflwirtURL = "https://staging.stefflwirt.web-stories.at";
    // stefflwirtURL = "http://localhost:3001";
    myBackendURL = "https://cms.web-stories.at"
    // myBackendURL = "http://localhost:5000"
} else {
    stefflwirtURL = "https://stefflwirt.at";
    // stefflwirtURL = "https://staging.stefflwirt.web-stories.at";
    myBackendURL = "https://cms.web-stories.at"
    // myBackendURL = "http://localhost:5000"
}


export const clientURL = stefflwirtURL
export const backendURL = myBackendURL


