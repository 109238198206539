import React from 'react';
import ReactDOM from 'react-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import './index.css';
import App from './App';
import DateFnsUtils from '@date-io/date-fns';
import axios from "axios";

// set axios to always send and receive cookies on backend requests
axios.defaults.withCredentials = true;

document.domain = window.location.hostname

ReactDOM.render(
    <React.StrictMode>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <App/>
        </MuiPickersUtilsProvider>
    </React.StrictMode>,

    document.getElementById('root')
)
;


