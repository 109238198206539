import {backendURL} from "./lib/environment";
import React, { useState} from "react";
import axios from "axios";
import Alert from "./components/Alert/Alert";
import WorkArea from "./WorkArea";

interface Props {
    logout: () => void
}

const InnerApp = ({logout}: Props) => {
    const [alert, setAlert] = useState<{ text: string, cb: () => void }>()

    const handleLogout = () => {
        axios.get(backendURL + "/api/auth/logout").then(() => logout())
    }

    return (
        <>
            {/*Modal for ThirdParty Cookies activation*/}
            <Alert alert={alert} close={() => setAlert(undefined)}/>

            <div className="inner-App">
                <div className={"logout"}>
                    <button onClick={handleLogout} className={"logoutButton"}>
                        <img alt="Logout" src={"/Logout-Symbol.svg"}/>
                    </button>
                </div>
                <WorkArea setAlert={setAlert}/>
            </div>
        </>
    )
}

export default InnerApp