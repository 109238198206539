import React from 'react';
import {Link, useLocation} from "react-router-dom";
import './Navbar.css';

function Navbar({width}) {

  const location = useLocation();

  return (
        <nav className={width < 700 ? "mobileNavbar" : "Navbar"}>
          <div className={location.pathname === "/" ? "current" : ""}><Link to="/">Home</Link></div>
          <div className={location.pathname === "/location" ? "current" : ""}><Link to="/location">Lokal</Link></div>
          <div className={location.pathname === "/cuisine" ? "current" : ""}><Link to="/cuisine">Küche</Link></div>
          <div className={location.pathname === "/rooms" ? "current" : ""}><Link to="/rooms">Zimmer</Link></div>
          <div className={location.pathname === "/partner" ? "current" : ""}><Link to="/partner">Partner</Link></div> 
          <div className={location.pathname === "/contact" ? "current" : ""}><Link to="/contact">Kontakt</Link></div>
        </nav>
  );
}

export default Navbar;