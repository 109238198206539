import React from 'react';
import {useState} from 'react';
import './App.css';
import Login from './Login';
import InnerApp from "./InnerApp";

function App() {

    const [loggedIn, setLoggedIn] = useState(false);
    return (
        <div className="App">
            <div className="background">
                <img alt="Background" src={"/background.jpg"}/></div>
            {loggedIn ?
                <InnerApp logout={() => setLoggedIn(false)}/> :
                <Login LoggedIn={setLoggedIn}/>
            }
        </div>
    );

}

export default App;